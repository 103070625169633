import React, { useState } from 'react';

const Contact = () => {
  return (
    /* ===== Contact Main Container ===== */
    <div 
      id='contact' 
      className='
        flex 
        flex-col 
        justify-center 
        items-center 
        bg-white 
        p-24 
        w-full
      '
    >
      {/* ===== Contact Title ===== */}
      <h1 
        className='
          text-3xl 
          text-gray-800 
        '
      >
        Contact Me
      </h1>
      {/* ===== Contact Main Form ===== */}
      <form 
        name='Contact Form' 
        method='post' 
        className='
          flex 
          flex-col 
          space-y-5 
          w-portfolioSm 
          sm:w-portfolioMd 
          p-4
          rounded-lg
        '
      >
        {/* ===== Netlify Form Data ===== */}
        <input 
          type="hidden" 
          name="form-name" 
          value="Contact Form"
        />
        {/* ===== Contact Form - Name Input ===== */}
        <label className='
            text-gray-700 
          '
        >
          Name:
          <input 
            type='text' 
            name='name' 
            placeholder='Your name (required)'
            className='
              mt-2
              py-2 
              px-3
              w-full
              rounded-md
              shadow-inner
              bg-gray-200
            ' 
            required 
          />
        </label>
        {/* ===== Contact Form - Email Input ===== */}
        <label className='
            text-gray-700 
          '
        >
          Email:
          <input 
            type='email' 
            name='email' 
            placeholder='Your email (required)'
            className='
              mt-2
              py-2 
              px-3
              rounded-md
              w-full
              shadow-inner
              bg-gray-200
            ' 
            required 
          />
        </label>
        {/* ===== Contact Form - Message Input ===== */}
        <label className='
            text-gray-700 
          '
        >
          Message:
          <textarea 
            name='message' 
            placeholder='Message (required)' 
            className='
              mt-2
              py-2 
              px-3
              h-64
              rounded-md
              w-full
              shadow-inner
              bg-gray-200
            '
            required 
          />
        </label>
        
        {/* ===== Contact Form - Submit Button Container ===== */}
        <div
          className='
            flex
            w-full
          '
        >
          {/* ===== Contact Form - Submit Button Container Spacer ===== */}
          <div className='flex-grow'/>
          {/* ===== Contact Form - Submit Button ===== */}
          <button 
            type='submit'
            className='
              px-3
              py-1
              text-gray-800
              border-2
              border-gray-600
              hover:bg-gray-800
              hover:text-white
              hover:border-0
              rounded-md
            '
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Contact;