import CodeImage from '../../Media/about-coding.jpg';
import MusicImage from '../../Media/about-music.jpg';
import GamingImage from '../../Media/about-gaming.jpg';

const AboutContent = [
  {
    title: 'Coding',
    image: CodeImage,
    alt: 'screen with coding',
    text: "Ever since I was a kid, I've loved all things technology. From programming Lego robots growing up to building Web Apps, I've always been obsessed with the feeling of watching my creations come to life. I'm constantly learning and looking for new ways to push my skills as a Developer."
  },
  {
    title: 'Gaming',
    image: GamingImage,
    alt: 'gaming controller',
    text: "When I'm not working on projects, you can usually find me gaming. I'm all about anything gaming related. RPGs are my preferred genre of choice, but I do like a good game of Mario Party here and there."
  },
  {
    title: 'Music',
    image: MusicImage,
    alt: 'music room with instruments',
    text: "Music is a big part of my life. I picked up a guitar in early high school and haven't put it down since. When I'm not writing songs for my band, Fallible, I'm writing for and recording other artists here in LA."
  }
];

export default AboutContent;