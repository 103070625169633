import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const AboutTopic = props => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  return (
    /* ===== About Topic Container ===== */
    <div 
      className={`
        flex 
        flex-col 
        md:flex-row 
        items-center 
        bg-gray-300 
        w-portfolioSm 
        sm:w-full 
        max-w-screen-lg 
        shadow-md 
        rounded-lg 
        my-8 
        p-4
        transform
        transition-transform
        duration-1000
        ease-out
        ${
          (inView) ? 
          'translate-x-0' :
          (props.animate === 'left') ?
          'translate-x-full' :
          (props.animate === 'right') ?
          '-translate-x-full' : ''
        }
      `}
      ref={ref}
    >
      {/* ===== Topic Image ===== */}
      <img 
        src={props.image} 
        alt={props.alt}
        className='
          w-full 
          sm:w-80 
          shadow-md 
          rounded-lg
        ' 
      />
      {/* ===== Topic Text Main Container ===== */}
      <div 
        className='
          flex 
          flex-col 
          items-center 
          sm:mx-8
        '
      >
        {/* ===== Topic Text Title ===== */}
        <h1 
          className='
            text-xl 
            mt-6 
            mb-4 
            md:mt-0
          '
        >
          {props.title}
        </h1>
        {/* ===== Topic Text ===== */}
        <p>
          {props.text}
        </p>
      </div>
    </div>
  );
}

export default AboutTopic;