import React from 'react';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

const Footer = () => {
  return (
    /* ===== Footer Main Container ===== */
    <div 
      className='
        flex 
        w-full 
        justify-center
        bg-white
      '
    >
      {/* ===== Footer Sub Container ===== */}
      <div 
        className='
          w-full
          max-w-screen-lg 
          py-3
          px-6
        '
      >
        {/* ===== Footer List Container ===== */}
        <ul 
          className='
            flex 
            items-center 
            justify-between
          '
        >
          {/* ===== Footer - Copyright Info ===== */}
          <li>
            <p>&#169; Shawn McAllister {new Date().getFullYear()}</p>
          </li>
          {/* ===== Footer - Links Container ===== */}
          <li 
            className='
              flex
              sm:mr-20
              lg:mr-0
            '
          >
            {/* ===== Footer - LinkedIn Container ===== */}
            <a 
              href='https://www.linkedin.com/in/shawn-mcallister/' 
              className='
                flex 
                mr-4
              '
              rel='noreferrer' 
              target='_blank'
            >
              {/* ===== Footer - LinkedIn Icon ===== */}
              <span 
                className='
                  text-2xl 
                  mr-2
                '
              >
                <FaLinkedin />
              </span>
              {/* ===== Footer - LinkedIn Text ===== */}
              <p 
                className='
                  hidden
                  sm:block
                '
              >
                LinkedIn
              </p>
            </a>
            {/* ===== Footer - Github Container ===== */}
            <a 
              href='https://github.com/shawnmca' 
              className='
                flex
              '
              rel='noreferrer' 
              target='_blank'
            >
              {/* ===== Footer - Github Icon ===== */}
              <span 
                className='
                  text-2xl 
                  mr-2
                '
              >
                <FaGithub />
              </span>
              {/* ===== Footer - Github Text ===== */}
              <p 
                className='
                  hidden
                  sm:block
                '
              >
                GitHub
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;