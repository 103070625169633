import { useState, useEffect } from 'react';
import './App.css';
import NavBar from './Components/NavBar';
import HeroMain from './Components/HeroMain';
import Portfolio from './Components/Portfolio/Portfolio';
import About from './Components/About/About';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import PageUpArrow from './Components/PageUpArrow';


function App() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isPageUpArrowVisible, setIsPageUpArrowVisible] = useState(false);

  // Sets Hero to screen size if not mobile width. Mobile had problems adjusting page.
  const updateWindowHeight = () => {
    if(window.screen.width > 550) setWindowHeight(window.innerHeight);
  }

  // Shows to top of page arrow if not at top of page
  const isTopOfPage = () => {
    setIsPageUpArrowVisible(() => (window.pageYOffset) ? true : false);
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowHeight);
    return () => window.removeEventListener('resize', updateWindowHeight);
  },[]);

  useEffect(() => {
    window.addEventListener('scroll', isTopOfPage);
    return () => window.removeEventListener('scroll', isTopOfPage);
  },[]);

  return (
    <div 
      className='
        App 
        flex 
        flex-col 
        min-w-full  
        overflow-y-auto
        overflow-x-hidden
      '
    >
      <NavBar />
      <HeroMain height={windowHeight}/>
      <Portfolio />
      <About />
      <Contact />
      <Footer />
      <PageUpArrow visible={isPageUpArrowVisible}/>
    </div>
  );
}

export default App;