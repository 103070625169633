import recipeApiModalImg from '../../Media/frontend/recipe-api-modal-sm.jpg';
import marioKartCalculator from '../../Media/frontend/mario-kart-calculator-sm.jpg';
import timeTrackerReact from '../../Media/frontend/time-tracker-react-sm.jpg';
import productTrackerImg from '../../Media/backend/product-tracker-sm.jpg';
import radBearStudiosBot from '../../Media/backend/rad-bear-studios-bot-sm.jpg';
import timeTrackerElectron from '../../Media/backend/time-tracker-electron-sm.jpg';
import portfolioSiteImg from '../../Media/sites/personal-site-sm.jpg';
import fallibleSiteImg from '../../Media/sites/fallible-site-sm.jpg';
import templateSiteImg from '../../Media/sites/template-site-sm.jpg';
import sleepingCavernsImg from '../../Media/games/sleeping-caverns-sm.jpg';
import matchingIconsImg from '../../Media/games/matching-game.jpg';

const Projects = [ 
  {
    tabName: 'Websites',
    projects: [
      { 
        title: 'Portfolio Website',
        imageUrl: portfolioSiteImg,
        gitHubUrl: '',
        demoUrl: '',
        technologies: 'React.js, Tailwind CSS, Netlify',
        description: 'Hey! This looks familiar. I made the website you\'re currently on with React.js and Tailwind CSS. Everything from the ground up is made by hand and showcases how far I\'ve come in my time as a developer. If you like it, feel free to reach out to me and let me know!' 
      },
      { 
        title: 'Fallible Website',
        imageUrl: fallibleSiteImg,
        gitHubUrl: '',
        technologies: 'JavaScript, CSS, HTML, Netlify',
        demoUrl: 'https://www.fallibleband.com',
        description: 'A custom made website for the rock band Fallible. The website features a section about the band members, a music player to listen to a few of their songs, and their latest music videos.'
      },
      { 
        title: 'React-Chakra UI Template',
        imageUrl: templateSiteImg,
        gitHubUrl: 'https://github.com/ShawnMcA/React-Chakra-UI-Website-Template',
        demoUrl: '',
        technologies: 'React.js, Chakra UI',
        description: 'I wanted to create a React/Chakra UI template to use for future projects. The template features a fullscreen hero image, call to action buttons, and a modern, responsive design.'
      }
    ],
  },
  {
    tabName: 'Front End',
    projects: [
      { 
        title: 'Recipe API Modal',
        imageUrl: recipeApiModalImg,
        gitHubUrl: 'https://github.com/ShawnMcA/API-Search-Modal-React',
        demoUrl: 'https://shawnmcallister-recipe-finder.netlify.app/',
        technologies: 'React JS, Tailwind CSS, Spoonacular API',
        description: 'React modal component used to fetch recipes based on the user\'s search. The component fetches data from the Spoonacular API via HTTP request and provides a convenient single location to find various recipes and ingredients. '
      },
      { 
        title: 'Mario Kart 8 Calculator',
        imageUrl: marioKartCalculator,
        gitHubUrl: 'https://github.com/ShawnMcA/Mario-Kart-8-Calculator',
        demoUrl: 'https://shawnmcallister-mariokart-calculator.netlify.app/',
        technologies: 'JavaScript, CSS, HTML, ApexCharts.js',
        description: 'The Mario Kart 8 calculator uses driver, kart, tire, and glider in-game statistics to provide a detailed chart for users to test various options. This application provides an easier way to view the various options with instant, easy to read statistics with more detail than in-game.'
      },
      { 
        title: 'Time Tracker React',
        imageUrl: timeTrackerReact,
        gitHubUrl: 'https://github.com/ShawnMcA/Time-Tracker-React',
        demoUrl: 'https://shawnmcallister-time-tracker.netlify.app/',
        technologies: 'React, CSS, LocalStorage',
        description: 'This React time tracking application is a recreation of my Electron desktop application. Creating the application in React allows users to access the application via the web without downloading a desktop application. It uses LocalStorage to keep a long-term record of the hours.'
      }
    ],
  },
  {
    tabName: 'Back End',
    projects: [
      { 
        title: 'Product Availability Tracker',
        imageUrl: productTrackerImg,
        gitHubUrl: 'https://github.com/ShawnMcA/Best-Buy-Product-Tracker-Public',
        demoUrl: '',
        technologies: 'Node.js, Best Buy API, Nodemailer',
        description: 'This product availability tracker uses Node.js to query the Best Buy Product API and sends a status update if an item is in stock. This was originally created to notify the user when a Playstation 5 was in stock, but can be adjusted to track any valid product SKU.'
      },
      { 
        title: 'Rad Bear Studios Discord Bot',
        imageUrl: radBearStudiosBot,
        gitHubUrl: '',
        demoUrl: '',
        technologies: 'Node.js, Discord API, MongoDB',
        description: 'I created this Discord Bot to manage the Discord group for our video game development company, Rad Bear Studios. It tracks user involvement via a point system in MongoDB to create various user levels. When a user reaches enough points, they are promoted to game tester.'
      },
      { 
        title: 'Time Tracker Electron',
        imageUrl: timeTrackerElectron,
        gitHubUrl: 'https://github.com/ShawnMcA/Time-Tracker',
        demoUrl: '',
        technologies: 'Node.js, Electron.js, Electron-Store',
        description: 'A Time Tracking desktop application made in JavaScript with Electron.js. I wanted to create an application to keep track of my time working on various projects. Persistant data is stored using Electron-Store to keep a running total.'
      }
    ],
  },
  { 
    tabName: 'Games',
    projects: [
      { 
        title: 'Sleeping Caverns',
        imageUrl: sleepingCavernsImg,
        gitHubUrl: '',
        demoUrl: '',
        technologies: 'Unity, C#',
        description: 'Sleeping Caverns is a computer based puzzle/dungeon explorer game created with Unity and C# by our game development company, Rad Bear Studios. Currently, I am the main C# developer on the team of 3. The game is currently in development with hopes to release to the public by the end of 2021.'
      },
      { 
        title: 'Matching Icons',
        imageUrl: matchingIconsImg,
        gitHubUrl: 'https://github.com/ShawnMcA/Icon-Matching-Game',
        demoUrl: 'https://shawnmcallister-matching-icons.netlify.app/',
        technologies: 'JavaScript, CSS, HTML',
        description: 'Matching Icons is one of the first JavaScript based games I\'ve created and features various color coded icons in a match-two style game. The player has 8 lives to match 8 pairs of tiles in order to win the game.'
      },
    ],
  }
];

export default Projects