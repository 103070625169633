import React from 'react';
import { BsChevronBarUp } from 'react-icons/bs';

const PageUpArrow = props => {
  return (
    <button 
      className={`
        fixed
        hidden
        right-3 
        bottom-3 
        text-5xl 
        text-white 
        bg-gray-700 
        rounded-full 
        p-2
        focus:outline-none
        shadow-md
        ${(props.visible) ? 'sm:block' : 'hidden'}
      `} 
      onClick={() => window.scrollTo({top: 0})}
    >
      <BsChevronBarUp />
    </button>
  );
}

export default PageUpArrow;