import React, { useEffect, useState } from 'react';
import PortfolioProject from './PortfolioProject';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';


const PortfolioBody = props => {

  const [currentProject, setCurrentProject] = useState(0);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  // Current portfolio project
  const createPortfolioComponent = () => {
    return (<PortfolioProject projectInfo={props.activeTab.projects[currentProject]} />);
  }

  // Tab button for swapping portfolio projects
  const createTabButtons = project => {
    return (
    <button 
      className={`
        w-3 
        h-3 
        ${(props.activeTab.projects.indexOf(project) === currentProject) ? 'bg-gray-700' : 'border-2 border-gray-700'} 
        rounded-full 
        shadow-md
        focus:outline-none
      `} 
      key={project.title} 
      onClick={() => setCurrentProject(props.activeTab.projects.indexOf(project))}
    />
    );
  }

  useEffect(() => {
    if(props.activeTab.projects.length > 1) {
      if(currentProject === 0) {
        setPrevButtonDisabled(true);
        setNextButtonDisabled(false);
      } else if (currentProject === props.activeTab.projects.length - 1) {
        setNextButtonDisabled(true);
        setPrevButtonDisabled(false);
      } else {
        setPrevButtonDisabled(false);
        setNextButtonDisabled(false);
      }
    }
  }, [props, currentProject]);

  return (
    /* ===== Portfolio Body Main Container ===== */
    <div 
      className='
        flex 
        flex-col 
        flex-grow 
        bg-white 
        h-full 
        w-full 
        rounded-md 
        shadow-inner 
        py-4 
        px-4 
        overflow-y-hidden
      '
    >
      {/* ===== Current Portfolio Project ===== */}
      <div 
        className='
          flex 
          flex-col 
          w-full 
          flex-grow 
          p-5 
          overflow-y-auto
        '
      >
        { createPortfolioComponent() }
      </div>
      {/* ===== Tab Buttons ===== */}
      <div 
        className='
          flex 
          w-full 
          items-center 
          justify-evenly 
          mt-2
          px-6 
        '
      >
        {/* ===== Previous Arrow ===== */}
        <button 
          className={`
            ${currentProject === 0 ? 'text-gray-300' : 'gray-700'} 
            text-2xl 
            focus:outline-none
            px-2
          `}
          onClick={() => setCurrentProject(currentProject => currentProject - 1)}
          disabled={prevButtonDisabled}
        >
          <BsArrowLeft />
        </button>
        {/* ===== Tab Buttons ===== */}
        <div 
          className='
            flex 
            space-x-4
          '
        >
          { props.activeTab.projects.map(project => createTabButtons(project)) }
        </div>
        {/* ===== Next Arrow ===== */}
        <button 
          className={`
            ${currentProject === props.activeTab.projects.length - 1 ? 'text-gray-300' : 'gray-700'} 
            text-2xl 
            focus:outline-none
            px-2
          `}
          onClick={() => setCurrentProject(currentProject => currentProject + 1)}
          disabled={nextButtonDisabled}
        >
          <BsArrowRight />
        </button>
      </div>
    </div>
  );
}

export default PortfolioBody;