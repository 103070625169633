import React, { useState } from 'react';
import PortfolioBody from './PortfolioBody';
import Projects from './Projects';

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    /* ===== Portfolio Main Container ===== */
    <div 
      id='portfolio' 
      className='
        flex 
        flex-col 
        justify-center 
        items-center 
        bg-secondary 
        py-24 
        w-full
      '
    >
      {/* ===== Portfolio Title ===== */}
      <h1 
        className='
          text-3xl 
          text-gray-800 
          mb-6
        '
      >
        Projects
      </h1>
      {/* ===== Portfolio App Main Container ===== */}
      <div 
        className='
          flex 
          flex-col 
          justify-center  
          rounded-lg 
          shadow-md 
          h-portfolioMd 
          min-w-portfolioSm 
          w-portfolioSm 
          sm:w-portfolioMd
        '
      >
        {/* ===== Portfolio App - Tabs ===== */}
        <div 
          className='
            flex 
            text-white 
            justify-evenly 
            w-full 
            divide-x
          '
        >
          {/* ===== Portfolio App - Front End Tab ===== */}
          <button 
            className={`
              flex 
              justify-center 
              items-center 
              py-3
              w-1/4
              rounded-t-lg
              focus:outline-none
              ${activeTab === 0 ? 'bg-gray-300 text-black' : 'bg-gray-700'}
            `}
            onClick={() => setActiveTab(0)}
          >
            <p>{Projects[0].tabName}</p>
          </button>
          {/* ===== Portfolio App - Back End Tab ===== */}
          <button 
            className={`
              flex 
              justify-center 
              items-center 
              py-3
              w-1/4 
              rounded-t-lg
              focus:outline-none
              ${activeTab === 1 ? 'bg-gray-300 text-black' : 'bg-gray-700'}
            `}
            onClick={() => setActiveTab(1)}
          >
            <p>{Projects[1].tabName}</p>
          </button>
          {/* ===== Portfolio App - Websites End Tab ===== */}
          <button 
            className={`
              flex 
              justify-center 
              items-center 
              py-3
              w-1/4
              rounded-t-lg
              focus:outline-none
              ${activeTab === 2 ? 'bg-gray-300 text-black' : 'bg-gray-700'}
            `}
            onClick={() => setActiveTab(2)}
          >
            <p>{Projects[2].tabName}</p>
          </button>
          {/* ===== Portfolio App - Games Tab ===== */}
          <button 
            className={`
              flex 
              justify-center 
              items-center 
              py-3
              w-1/4
              rounded-t-lg
              focus:outline-none
              ${activeTab === 3 ? 'bg-gray-300 text-black' : 'bg-gray-700'}
            `}
            onClick={() => setActiveTab(3)}
          >
            <p>{Projects[3].tabName}</p>
          </button>
        </div>
        {/* ===== Portfolio App - Body ===== */}
        <div 
          className='
            flex 
            flex-col 
            flex-grow 
            bg-gray-300 
            rounded-b-lg 
            p-3 
            overflow-y-auto
          '
        >
          <PortfolioBody activeTab={Projects[activeTab]} key={activeTab}/>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;