import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

const NavBar  = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return(
    /* ===== NavBar Main Container ===== */
    <div 
      className={`
        animate-moveDown
        flex 
        justify-center
      `} 
      id='navbar'
    >
      {/* ===== NavBar List Container - Logo ===== */}
      <ul 
        className='
          absolute
          flex 
          flex-col
          justify-between 
          font-main
          text-xl
          w-full
          max-w-screen-lg
          sm:flex-row
          sm:divide-none
        '
      >
        {/* ===== List Item - Logo ===== */}
        <li 
          className={`
            flex 
            justify-between
            ${menuOpen ? 'bg-gray-800' : 'bg-transparent'} 
          `}
        >
          {/* ===== List Link - Logo ===== */}
          <p 
            className='
              block 
              text-white 
              pl-6 
              py-4 
              w-full  
            '
          >
            SHAWN MCALLISTER
          </p>
          {/* ===== NavBar Open/Close Icon ===== */}
          <button 
            className={`
              transform ${!menuOpen ? 'rotate-0' : 'rotate-180'} 
              sm:hidden 
              transition-transform 
              text-white 
              px-6 
              text-3xl 
              focus:outline-none 
              hover:text-gray-400
            `} 
            onClick={() => setMenuOpen(menuOpen => !menuOpen)}
          >
            <FiChevronDown />
          </button>
        </li>
        {/* ===== NavBar List Container - Links ===== */}
        <ul 
          className={`
            ${menuOpen ? 'flex' : 'hidden'} 
            flex-col 
            sm:divide-none 
            sm:flex 
            sm:flex-row
          `}
        >
          {/* ===== List Item - Portfolio ===== */}
          <li 
            className='
              bg-gray-800 
              sm:bg-transparent
            '
          >
            {/* ===== List Link - Portfolio ===== */}
            <a 
              href='#portfolio' 
              className='
                block 
                text-white 
                pl-6 
                py-4
                sm:pl-0 
                sm:pr-6  
                hover:text-gray-400
              '
            >
              PORTFOLIO
            </a>
          </li>
          {/* ===== List Item - About ===== */}
          <li 
            className='
              bg-gray-800 
              sm:bg-transparent
            '
          >
            {/* ===== List Link - About ===== */}
            <a 
              href='#about' 
              className='
                block 
                text-white 
                pl-6 
                sm:pl-0 
                sm:pr-6 
                py-4 
                hover:text-gray-400
              '
            >
              ABOUT
            </a>
          </li>
          {/* ===== List Item - Contact ===== */}
          <li 
            className='
              bg-gray-800 
              sm:bg-transparent
            '
          >
            {/* ===== List Link - Contact ===== */}
            <a 
              href='#contact' 
              className='
                block 
                text-white 
                pl-6 
                sm:pl-0 
                sm:pr-6 
                py-4 
                hover:text-gray-400
              '
            >
              CONTACT
            </a>
          </li>
        </ul>
      </ul>
    </div>
  );
}

export default NavBar;