import React from 'react';
import AboutTopic from './AboutTopic';
import AboutContent from './AboutContent';

const About = () => {
  return(
    /* ===== About Main Container ===== */
    <div 
      id='about'
      className='
        flex 
        flex-col 
        items-center 
        justify-center 
        bg-gray-800 
        py-24 
        px-14 
        w-full
      '
    >
      {/* ===== About Title ===== */}
      <h1 
        className='
          text-white 
          text-3xl
        '
      >
        Who Am I?
      </h1>
      {/* ===== About - Coding ===== */}
      <AboutTopic 
        image={AboutContent[0].image} 
        alt={AboutContent[0].alt}
        title={AboutContent[0].title}
        text={AboutContent[0].text}
        animate='left'
      />
      {/* ===== About - Gaming ===== */}
      <AboutTopic 
        image={AboutContent[1].image} 
        alt={AboutContent[1].alt}
        title={AboutContent[1].title}
        text={AboutContent[1].text}
        animate='right'
      />
      {/* ===== About - Music ===== */}
      <AboutTopic 
        image={AboutContent[2].image}
        alt={AboutContent[2].alt} 
        title={AboutContent[2].title}
        text={AboutContent[2].text}
        animate='left'
      />
    </div>
  );
}

export default About;