import React from 'react';

const PortfolioProject = props => {
  return (
    /* ===== Portfolio Project Main Container ===== */
    <div className='
        flex 
        flex-col 
        items-center 
        h-full 
        w-full 
        space-y-3
      '
    >
      {/* ===== Project Title ===== */}
      <h2 
        className='
          text-xl
        '
      >
        {props.projectInfo.title}
      </h2>
      {/* ===== Project Image ===== */}
      <img 
        className='
          shadow-lg
        '
        src={props.projectInfo.imageUrl} 
        style={{ height: '150px' }} 
        alt='projects'
      />
      {/* ===== Project Links ===== */}
      <div 
        className='
          flex 
          justify-evenly
        '
      >
        <a 
          className={`
            ${props.projectInfo.demoUrl ? 'block' : 'hidden'}
            ${props.projectInfo.gitHubUrl ? 'mr-4' : 'mr-0'} 
            px-1 
            border-2 
            border-gray-700 
            rounded-md
            hover:bg-gray-700
            hover:text-white
          `}
          href={props.projectInfo.demoUrl} 
          rel='noreferrer' 
          target='_blank'
        >
          Demo
        </a>
        <a 
          className={`
            ${props.projectInfo.gitHubUrl ? 'block' : 'hidden'}
            px-1 
            border-2 
            border-gray-700 
            rounded-md
            hover:bg-gray-700
            hover:text-white
          `} 
          href={props.projectInfo.gitHubUrl} 
          rel='noreferrer' 
          target='_blank'
        >
          GitHub
        </a>  
      </div>
      {/* ===== Project Description ===== */}
      <p>Tech Used: <i>{props.projectInfo.technologies}</i></p>
      {/* ===== Project Description ===== */}
      <p>{props.projectInfo.description}</p>
    </div>
  );
}

export default PortfolioProject;