import React from 'react';
import { BsChevronDoubleDown } from 'react-icons/bs';

const HeroMain = props => {
  return(
    /* ===== Hero Main Container - Bg Image ===== */
    <div 
      className='
        bg-hero-bg 
        bg-center
        bg-no-repeat 
        bg-cover 
        w-full 
      '
      id='hero-main'
      style={{height: props.height}}
    >
      {/* ===== Hero Main Container - Gradient ===== */}
      <div 
        className='
          flex 
          flex-col 
          justify-center 
          h-full 
          w-full
          items-center
          bg-gradient-to-b
          from-gray-800
          via-transparent
        '
      >
        {/* ===== FlexBox Spacer ===== */}
        <div />
        {/* ===== Hero Main Content Container ===== */}
        <div 
          className='flex flex-col justify-center items-center animate-moveRight'>
          {/* ===== Main Content - Portrait ===== */}
          <div 
            className='
              bg-hero-image 
              bg-center 
              bg-no-repeat 
              bg-cover 
              border-2 
              border-opacity-70 
              border-main 
              rounded-full 
              w-40 
              h-40 
              shadow-lg
            '
          >
          </div>
          {/* ===== Main Content - Header ===== */}
          <h1 
            className='
              mt-2 
              text-white 
              text-4xl 
              sm:text-5xl
            ' 
            style={{textShadow: "3px 2px 3px rgba(0, 0, 0, 0.3)"}}
          >
            SHAWN MCALLISTER
          </h1>
          {/* ===== Main Content - Sub Header ===== */}
          <h2 
            className='
              text-lg 
              text-white 
              sm:text-xl
            ' 
            style={{textShadow: "3px 2px 3px rgba(0, 0, 0, 0.3)"}}
          >
            Software Developer - Web Developer 
          </h2>
          {/* ===== Main Content - Sub Header 2 ===== */}
          <h2 
            className='
              text-lg 
              text-white 
              sm:text-xl
            '
            style={{textShadow: "3px 2px 3px rgba(0, 0, 0, 0.3)"}}
          >
            Los Angeles, CA 
          </h2>
        </div>
        {/* ===== Main Content - Scroll Icon ===== */}
        <a 
          href='#portfolio' 
          className='
            animate-bounce 
            absolute 
            text-white 
            text-4xl 
            bottom-10 
            rounded-full 
            hover:bg-white 
            hover:bg-opacity-25 
            p-2
          '
        >
          <BsChevronDoubleDown />
        </a>
      </div>
    </div>
  );
}

export default HeroMain;